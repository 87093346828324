import { styled, SxProps, Typography } from "@mui/material";

export const AppText = styled(Typography)(({theme}) => {
  return {
    fontWeight: 700,
    fontSize: '1.3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    }
  }
})

type Props = {
  children?: React.ReactNode,
  sx?: SxProps,
  fontWeight?: number,
  onClick?: () => void
  hover?: boolean
}

export const AppTextNormal: React.FC<Props> = ({
  children, 
  sx, fontWeight, 
  onClick, hover
}) => <AppText variant="body1" 
        sx={{
          ...sx,
          "&:hover": {
            borderBottom: hover ? "1px solid white" : null,
          }
        }} 
        fontWeight={fontWeight ?? undefined} 
        onClick={onClick ?? undefined}>{children}</AppText>