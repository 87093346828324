import { Stack, TextField, useTheme } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { COLORS } from "../shared/colors";
import { AppTextNormal } from "../shared/components/AppText";
import { GameButton } from "../shared/components/GameButton";

interface SupportResponse {
  errors?: { value: string, msg: string }[]
  successful?: boolean
  text?: string
}

export const Support: React.FC = () => {
  const theme = useTheme()
  const [text, setText] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [response, setResponse] = useState<SupportResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value.slice(0, 300))
  }

  const submitSupport = async () => {
    const baseUrl = process.env.REACT_APP_BASE_API
    setResponse(null)
    setLoading(true)
    try {
      const response = await axios.post<SupportResponse>(`${baseUrl}/app/support`, {
        text,
        email
      })
      setResponse(response.data)
      if (response.data.successful) {
        setEmail('')
        setText('')
      }
    } catch (error) {
      const { response } = error as any
      setResponse(response.data)
    }
    setLoading(false)
  }

  const remainingCharacters = 300 - text.length

  return (
    <Stack alignItems={'center'}>
      <Stack alignItems={'center'} spacing={2} sx={{
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
          width: '100%'
        },
      }}>
        <AppTextNormal sx={{ marginBottom: 1 }}>Contact Support</AppTextNormal>
        <TextField value={email} label="Email" variant="filled" onChange={handleEmailChange} fullWidth sx={{ backgroundColor: 'white' }} />
        <TextField value={text} label="Your message"
          multiline
          sx={{ backgroundColor: 'white' }}
          rows={7}
          variant="filled" onChange={handleTextChange} fullWidth />
        <AppTextNormal sx={{ marginBottom: 1 }}>Remaining characters: {remainingCharacters}</AppTextNormal>
        {
          response?.errors ? response.errors.map(error => <AppTextNormal sx={{ color: 'red' }}>{error.msg}</AppTextNormal>) : <></>
        }
        {
          response?.successful ? <AppTextNormal sx={{ color: 'white' }}>{response!.text}</AppTextNormal> : <></>
        }
        <GameButton onClick={submitSupport} disabled={!text || !email}>
          { loading ? <ClipLoader /> : 'Send' }
        </GameButton>
      </Stack>
    </Stack>
  )
}