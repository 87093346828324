export const PAGES = [{
  url: 'privacy',
  name: 'Privacy'
}, {
  url: 'terms', 
  name: 'Terms'
}, {
  url: 'dispute-policy', 
  name: 'Dispute Policy'
}, {
  url: 'biometric-consent',
  name: 'Biometric Consent'
}]