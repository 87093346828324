import { Box, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GameButton } from '../shared/components/GameButton';
import { RankingRow } from '../shared/components/RankingRow';
import { IRanking } from '../shared/models/ranking';

export const Home: React.FC = () => {
  const [rankings, setRankings] = useState<IRanking[]>([])

  const request = async () => {
    const baseUrl = process.env.REACT_APP_BASE_API
    const response = await axios.get<IRanking[]>(`${baseUrl}/users/ranking`)
    setRankings(response.data)
  }

  useEffect(() => {
    request()
  }, [])

  const redirectToGame = () => {
    if (process.env.REACT_APP_REDIRECT_GAME) {
      window.location.href = process.env.REACT_APP_REDIRECT_GAME
    }
  }

  return (
    <Stack alignItems={'center'} >
      <Typography variant='h3' color="white" fontWeight={'bolder'} marginBottom={2}>Ranking</Typography>
      <Stack direction='row' spacing={4}>
        <GameButton sx={{marginBottom: 3}} onClick={redirectToGame}>Play Game</GameButton>
        <Box component={'img'}
          sx={{ height: '60px', marginTop: '8px !important' }}
          src="images/app_store.svg" alt="Download app"
        />
      </Stack>
      <Grid container marginBottom={5}>
        <RankingRow displayName='Name' points='Points' position='Position' />
        {
          rankings.map((ranking) => (
            <RankingRow displayName={ranking.displayName} points={ranking.points} position={ranking.ranking} />
          ))
        }
      </Grid>
    </Stack>
  );
}