import { Grid, Typography } from "@mui/material";
import React from "react";

interface IProps {
  position: number | 'Position'
  displayName: string | 'Name'
  points: number | 'Points'
}

export const RankingRow: React.FC<IProps> = ({ displayName, points, position }: IProps) => {
  return (
    <Grid container sx={{ height: 30, }}>
      <Grid item xs={2} textAlign='center'>
        <Typography variant="body1">{position}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{displayName}</Typography>
      </Grid>
      <Grid item xs={4} textAlign='right'>
        <Typography variant="body1">{points}</Typography>
      </Grid>
    </Grid>
  )
}