import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Home } from './Home';
import { PAGES } from './shared/pages';
import { StaticPage } from './StaticPage';
import { Support } from './Support';

function App() {
  const history = useHistory()

  const goToHome = () => history.push('/')
  const goToSupport = () => history.push('/support')
  const goToStatic = (page: string) => history.push(`/${page}`)

  return (
    <Stack marginY={5} sx={{ minWidth: 350, maxWidth: 700, marginX: 'auto' }} height={'90vh'}>
      <Box component={'img'}
        sx={{ width: '100%', marginBottom: 4 }}
        src="/images/td-logo.png" alt="Truth Detective Logo"
      />
      <Stack direction={'row'} spacing={3} alignContent='center' alignItems={'center'} width={'100%'} justifyContent={'center'} paddingBottom={2}>
        <Typography margin={0} onClick={goToHome} variant='h6' color="white" fontWeight={'bolder'} sx={{ cursor: 'pointer', '&:hover': { borderBottom: '1px solid white' } }}>Home</Typography>
        <Typography margin={0} onClick={goToSupport} variant='h6' color="white" fontWeight={'bolder'} sx={{ cursor: 'pointer', '&:hover': { borderBottom: '1px solid white' } }}>Support</Typography>
        {
          PAGES.map(page => (
            <Typography margin={0} onClick={() => goToStatic(page.url)} variant='h6' color="white" fontWeight={'bolder'} sx={{ cursor: 'pointer', '&:hover': { borderBottom: '1px solid white' } }}>{page.name}</Typography>
          ))
        }
      </Stack>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/support">
          <Support />
        </Route>
        {
          PAGES.map(page => (
            <Route exact path={`/${page.url}`}>
              <StaticPage name={page.url} />
            </Route>
          ))
        }
      </Switch>
    </Stack>
  );
}

export default App;
