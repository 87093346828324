import { Button, styled } from "@mui/material";
import { COLORS } from "../colors";

interface IProps {
  padding?: string
}

export const GameButton = styled(Button)<IProps>((props) => {
  return {
    color: COLORS.blue,
    borderRadius: 25,
    padding: props.padding ?? '0.5rem 3rem',
    marginTop: '8px',
    border: 'solid 2px transparent',
    background: `linear-gradient(45deg, ${COLORS.playButton.yellow.yellow1} 0%,${COLORS.playButton.yellow.yellow2} 50%,${COLORS.playButton.yellow.yellow3} 100%)`,
    fontWeight: 700,
    fontSize: '1.3rem',
    "&:hover": {
      color: 'white',
      border: 'solid 2px white',
      background: `linear-gradient(45deg, ${COLORS.playButton.blue.blue1} 0%,${COLORS.playButton.blue.blue2} 51%,${COLORS.playButton.blue.blue3} 100%)`
    },
    "&:disabled": {
      background: `rgb(66, 101, 138)`,
      color: '#6e7985'
    }
  }
})