import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'
import { COLORS } from './shared/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Red Hat Display',
    body1: {
      color: 'white',
      fontWeight: 'bold'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
          color: "black",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          sx: {
            color: COLORS.blue,
          }
        }
      },
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: COLORS.blue,
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: COLORS.blue,
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: COLORS.blue,
              '& legend': {
                color: COLORS.blue,
              }
            },
            '&.Mui-focused fieldset': {
              borderColor: COLORS.blue,
              '& legend': {
                color: COLORS.blue,
              }
            },
          }
        }
      }
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode> 
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
