export const COLORS = {
  blue: '#1673be',
  yellow: '#ffd800',
  playButton: {
    yellow: {
      yellow1: '#ffd800',
      yellow2: '#ffd900',
      yellow3: '#ffef00',
    },
    blue: {
      blue1: '#01469a',
      blue2: '#017dea',
      blue3: '#44caff',
    }
  },
}