import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'

interface IProps {
  name: string
}

export const StaticPage: React.FC<IProps> = ({name}: IProps) => {
  const [url, setUrl] = useState<string>("")

  const request = async () => {
    const baseUrl = process.env.REACT_APP_BASE_API
    const response = await axios.post<{type: string, signedUrl: string}>(`${baseUrl}/app/getPage`, {
      "type": name,
    })
    setUrl(response.data.signedUrl)
  }

  useEffect(() => {
    request()
  }, [])

  useEffect(() => {
    setUrl('')
    request()
  }, [name])

  return (
    <Stack alignItems={'center'}>
      {/* <Typography variant='h2' color="white" fontWeight={'bolder'} marginBottom={2}>RTScale.AI</Typography> */}
      <Stack alignItems={'center'} width={'100%'}>
        { 
          url ? (
            <>
              {/* <Typography variant='h3' color="white" fontWeight={'bolder'} marginBottom={2}>Privacy Policy</Typography> */}
              <Box sx={{
                backgroundColor: 'white',
                width: '100%',
                borderRadius: 10,
                padding: 3,
                height: '75vh'
              }}>
                <iframe src={url} style={{border: 0, width: '100%', height: '100%'}} />
              </Box>
            </>
          ) : <ClipLoader size={150} />
        }
      </Stack>
    </Stack>
  );
}